export default defineNuxtRouteMiddleware((to, from) => {
  const showModal = useState('showCookieModal', () => false)

  if (process.client) {
    const gdprAccepted = useCookie('gdprAccepted')
    if (!gdprAccepted.value) {
      showModal.value = true
    }
  }
  return
})
