import { API } from 'aws-amplify'
import { GraphQLQuery } from '@aws-amplify/api'
import * as queries from '../src/graphql/queries'
import { ListFragebogensQuery, GetTodoQuery } from '../src/API'

class FragebogenRepository {
  getFragebogen() {
    return API.graphql<GraphQLQuery<ListFragebogensQuery>>({
      query: queries.listFragebogens
    })
  }
}

export default FragebogenRepository
