import { Auth } from 'aws-amplify'
import { API } from 'aws-amplify'
import * as queries from '@/src/graphql/queries'

/* 
Runs first in to protect Admin routes from non-admin users
*/

export default defineNuxtRouteMiddleware(async (to, from, next) => {
  console.log('auth.global.js')
  const authStore = useAuthStore()
  const { setUser, user } = authStore
  if (user) {
    return
  }

  try {
    const cognito_user = await Auth.currentAuthenticatedUser()
    const db_user = (
      await API.graphql({
        query: queries.getUser,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: { id: cognito_user.attributes.sub }
      })
    ).data.getUser
    setUser(db_user)
  } catch (error) {
    setUser(null)
  }
})
