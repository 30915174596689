import validate from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/codebuild/output/src1226496911/src/e4f-aws/middleware/auth.global.js";
import gdpr_45global from "/codebuild/output/src1226496911/src/e4f-aws/middleware/gdpr.global.js";
import manifest_45route_45rule from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  gdpr_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  adminauth: () => import("/codebuild/output/src1226496911/src/e4f-aws/middleware/adminauth.js"),
  partnerguard: () => import("/codebuild/output/src1226496911/src/e4f-aws/middleware/partnerguard.js"),
  reportauth: () => import("/codebuild/output/src1226496911/src/e4f-aws/middleware/reportauth.js")
}