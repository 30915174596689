import { createVuetify } from 'vuetify'

import '@mdi/font/css/materialdesignicons.min.css'
import '@/assets/style.scss'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    ssr: false,
    theme: {
      themes: {
        light: {
          colors: {
            primary: '#992B3A',
            secondary: '#b0bec5'
          }
        }
      }
    }
  })

  nuxtApp.vueApp.use(vuetify)
})
