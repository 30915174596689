import { defineNuxtPlugin } from '#app'
import FragebogenRepository from '~/repository/fragebogen'

export default defineNuxtPlugin(nuxtApp => {
  const repositories = {
    fragebogen: new FragebogenRepository()
  }
  return {
    provide: {
      api: repositories // provide API instance to Nuxt app
    }
  }
})
