import { default as fragebogen2ASlAcJTDGMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/fragebogen.vue?macro=true";
import { default as indexZV0ezBvSeKMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/index.vue?macro=true";
import { default as indexZ4FO9HhWJPMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/[userId]/[reportId]/index.vue?macro=true";
import { default as indexj4qPtss6PCMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/[userId]/index.vue?macro=true";
import { default as indexzcpsQEOFQ9Meta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/index.vue?macro=true";
import { default as newKlientyy63nmkMy0Meta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/newKlient.vue?macro=true";
import { default as indexmm3VNsxP5tMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/index.vue?macro=true";
import { default as index5ru4MstEzCMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/[id]/index.vue?macro=true";
import { default as mandantDw5jmK0zfbMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/[id]/mandant.vue?macro=true";
import { default as userOf1tUHZMyuMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/[id]/user.vue?macro=true";
import { default as indexbXa0eECBtWMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/index.vue?macro=true";
import { default as adminl0MmCLvdyrMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/admin.vue?macro=true";
import { default as datenschutzAwsXnnmgO9Meta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/datenschutz.vue?macro=true";
import { default as ImpressumjA3qE3RyIqMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/Impressum.vue?macro=true";
import { default as indexSMV0Iawa43Meta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/index.vue?macro=true";
import { default as kontaktfvwF7VsAJrMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/kontakt.vue?macro=true";
import { default as login_dep1MPtxmyOarHMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/login_dep1.vue?macro=true";
import { default as loginsPvrG4Qy5hMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/login.vue?macro=true";
import { default as indexCbrsarXNf4Meta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/programm/coaching/index.vue?macro=true";
import { default as indexKVMne0PFRaMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/programm/E4F360Assessment/index.vue?macro=true";
import { default as indexS9POz9YdyCMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/programm/index.vue?macro=true";
import { default as indexi60T8sgkhrMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/programm/Journey/index.vue?macro=true";
import { default as _91reportId_93xSz7G6injhMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/report/[reportId].vue?macro=true";
import { default as indexrB6HE31sXsMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/report/index.vue?macro=true";
import { default as indicateStakeholderB3hqX6KVmqMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/report/indicateStakeholder.vue?macro=true";
import { default as reportkLydbfk7nTMeta } from "/codebuild/output/src1226496911/src/e4f-aws/pages/report.vue?macro=true";
export default [
  {
    name: adminl0MmCLvdyrMeta?.name,
    path: "/admin",
    meta: adminl0MmCLvdyrMeta || {},
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin.vue"),
    children: [
  {
    name: "admin-fragebogen",
    path: "fragebogen",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/fragebogen.vue")
  },
  {
    name: "admin",
    path: "",
    meta: indexZV0ezBvSeKMeta || {},
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/index.vue")
  },
  {
    name: "admin-mandanten-mandantId-userId-reportId",
    path: "mandanten/:mandantId()/:userId()/:reportId()",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/[userId]/[reportId]/index.vue")
  },
  {
    name: "admin-mandanten-mandantId-userId",
    path: "mandanten/:mandantId()/:userId()",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/[userId]/index.vue")
  },
  {
    name: "admin-mandanten-mandantId",
    path: "mandanten/:mandantId()",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/index.vue")
  },
  {
    name: "admin-mandanten-mandantId-newKlient",
    path: "mandanten/:mandantId()/newKlient",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/[mandantId]/newKlient.vue")
  },
  {
    name: "admin-mandanten",
    path: "mandanten",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/mandanten/index.vue")
  },
  {
    name: "admin-partner-id",
    path: "partner/:id()",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/[id]/index.vue")
  },
  {
    name: "admin-partner-id-mandant",
    path: "partner/:id()/mandant",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/[id]/mandant.vue")
  },
  {
    name: "admin-partner-id-user",
    path: "partner/:id()/user",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/[id]/user.vue")
  },
  {
    name: "admin-partner",
    path: "partner",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/admin/partner/index.vue")
  }
]
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/datenschutz.vue")
  },
  {
    name: "Impressum",
    path: "/Impressum",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/Impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/kontakt.vue")
  },
  {
    name: "login_dep1",
    path: "/login_dep1",
    meta: login_dep1MPtxmyOarHMeta || {},
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/login_dep1.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginsPvrG4Qy5hMeta || {},
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/login.vue")
  },
  {
    name: "programm-coaching",
    path: "/programm/coaching",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/programm/coaching/index.vue")
  },
  {
    name: "programm-E4F360Assessment",
    path: "/programm/E4F360Assessment",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/programm/E4F360Assessment/index.vue")
  },
  {
    name: "programm",
    path: "/programm",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/programm/index.vue")
  },
  {
    name: "programm-Journey",
    path: "/programm/Journey",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/programm/Journey/index.vue")
  },
  {
    name: reportkLydbfk7nTMeta?.name,
    path: "/report",
    meta: reportkLydbfk7nTMeta || {},
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/report.vue"),
    children: [
  {
    name: "report-reportId",
    path: ":reportId()",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/report/[reportId].vue")
  },
  {
    name: "report",
    path: "",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/report/index.vue")
  },
  {
    name: "report-indicateStakeholder",
    path: "indicateStakeholder",
    component: () => import("/codebuild/output/src1226496911/src/e4f-aws/pages/report/indicateStakeholder.vue")
  }
]
  }
]