import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1226496911/src/e4f-aws/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/codebuild/output/src1226496911/src/e4f-aws/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_amplify_NmKz5HDyqI from "/codebuild/output/src1226496911/src/e4f-aws/plugins/01.amplify.ts";
import _02_api_bgyRz33mcA from "/codebuild/output/src1226496911/src/e4f-aws/plugins/02.api.ts";
import sentry_3AyO8nEfhE from "/codebuild/output/src1226496911/src/e4f-aws/plugins/sentry.ts";
import vuetify_8NhHJigKc1 from "/codebuild/output/src1226496911/src/e4f-aws/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _01_amplify_NmKz5HDyqI,
  _02_api_bgyRz33mcA,
  sentry_3AyO8nEfhE,
  vuetify_8NhHJigKc1
]